import React, { ReactNode } from "react";

interface IDsdAlerteProps {
  id: string;
  type?: string;
  dynamic: boolean;
  fullWidth?: boolean;
  children?: ReactNode;
  tabIndex?: number;
  datatestid?: string;
}

export const DsdAlerte: React.FC<IDsdAlerteProps> = ({
  id,
  type,
  dynamic,
  fullWidth,
  children,
  tabIndex,
  datatestid,
}) => {
  return (
    <dsd-alert
      id={id}
      variant={type}
      dynamic={dynamic}
      full-width={fullWidth}
      tabIndex={tabIndex}
      data-testid={datatestid}
    >
      {children}
    </dsd-alert>
  );
};
