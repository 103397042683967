import i18n from "../../resources/I18n";
import * as MENU_CONFIG from "../../util/MenuConfig";
import { getMetaTagContent } from "../../scripts/getMetaTag";

export interface IStepIndicator {}

export default function StepIndicator(props: IStepIndicator) {
  let isApp = getMetaTagContent("oel-is-app") === "true";

  return (
    <dsd-step-indicator
      substeps-numbering="false"
      steps={i18n.language === "fr" ? MENU_CONFIG.FR : MENU_CONFIG.EN}
      skip-to-content-id="main-content-title"
      class="dsd-w-100"
      data-testid="step-indicator"
    >
      {!isApp && <span slot="title">{i18n.t("jampp:demander-pret-personnel")}</span>}
      <span slot="subtitle-mobile">{i18n.t("jampp:voir-details-etapes")}</span>
      <span slot="subtitle">{i18n.t("jampp:etape-5-de-7")}</span>
    </dsd-step-indicator>
  );
}
