export function loadNavigationScripts(project) {
    let navigationVersion

    if (project === "APRHN") {
        navigationVersion = '1.3.3'
    } else {
        navigationVersion = '1.3.9'
    }

    const scriptModule = document.createElement('script')
    scriptModule.type = 'module'
    scriptModule.src = `https://static.desjardins.com/paas/accesdc-navigation/webcomponents-navigation/${navigationVersion}/webcomponents-navigation/webcomponents-navigation.esm.js`;
    scriptModule.setAttribute('nonce', '');

    const scriptNoModule = document.createElement('script');
    scriptNoModule.setAttribute('nomodule', '');
    scriptNoModule.src = `https://static.desjardins.com/paas/accesdc-navigation/webcomponents-navigation/${navigationVersion}/webcomponents-navigation/webcomponents-navigation.js`;
    scriptNoModule.setAttribute('nonce', '');

    document.head.appendChild(scriptModule);
    document.head.appendChild(scriptNoModule);
}