import { IEnvironnement, Environnement } from "../../util/Constantes";
interface IAuthConfig {
  domain: string;
  clientId: string;
  issuer: string;
  redirectUri: string;
  acceswebUrl: string;
  audience: string;
  scope: string;
  creerSessionLegacy?: string;
  connection: string;
  moniteurActiviteActif?: string;
}

export interface IMetaTag {
  name: string;
  content: string;
}
export type MetaTagsArray = Array<IMetaTag>;

export const MetagTagNames: IAuthConfig = {
  domain: "oel-authentification-domain",
  clientId: "oel-authentification-clientId",
  issuer: "oel-authentification-issuer",
  acceswebUrl: "oel-acces-web-url",
  redirectUri: "oel-authentification-redirect-uri",
  audience: "oel-authentification-audience",
  scope: "oel-authentification-scope",
  creerSessionLegacy: "oel-authentification-creer-session-legacy",
  connection: "oel-authentification-connection",
  moniteurActiviteActif: "oel-authentification-moniteur-activite-actif",
};

export interface IContent {
  domain: IEnvironnement;
  clientId: IEnvironnement;
  scope: string;
  audience: IEnvironnement;
  issuer: IEnvironnement;
  creerSessionLegacy: string;
  moniteurActiviteActif: string;
  connection: IEnvironnement;
  connectionSiteMinder: IEnvironnement;
  redirectUri: IEnvironnement;
  acceswebUrl: IEnvironnement;
}

const Content: IContent = {
  domain: {
    local: "id.dev.desjardins.com",
    dev: "id.dev.desjardins.com",
    certification: "id.dev.desjardins.com",
    integre: "id.dev.desjardins.com",
    preprod: "id.dev.desjardins.com",
    prod: "id.desjardins.com",
  },
  // Votre clientId
  clientId: {
    local: "pHlQrL1meKqlEdKYFsw55xylS4LRRwwj",
    dev: "pHlQrL1meKqlEdKYFsw55xylS4LRRwwj",
    certification: "pHlQrL1meKqlEdKYFsw55xylS4LRRwwj",
    integre: "pHlQrL1meKqlEdKYFsw55xylS4LRRwwj",
    preprod: "T66U5NIsWJc3q2aOBFFoRFZ8296J74LN",
    prod: "jBHmDXZLfVqcH9HZp76Ui2PfKCnghW41",
  },
  issuer: {
    local: "id.dev.desjardins.com",
    dev: "id.dev.desjardins.com",
    certification: "id.dev.desjardins.com",
    integre: "id.dev.desjardins.com",
    preprod: "id.dev.desjardins.com",
    prod: "id.desjardins.com",
  },
  // Votre scope
  scope: "asspart.assurabilite.questionnaire.remplir",
  audience: {
    local: "pise-mc-assuranceproduitfinancier",
    dev: "pise-mc-assuranceproduitfinancier",
    certification: "pise-mc-assuranceproduitfinancier",
    integre: "pise-mc-assuranceproduitfinancier",
    preprod: "pise-mc-assuranceproduitfinancier",
    prod: "pise-mc-assuranceproduitfinancier",
  },
  creerSessionLegacy: "false",
  moniteurActiviteActif: "true",
  connection: {
    local: "AcceswebMultiDev",
    dev: "AcceswebMultiDev",
    certification: "AcceswebMultiDev",
    integre: "AcceswebMultiDev",
    preprod: "AcceswebMultiDev",
    prod: "AcceswebDirect",
  },
  connectionSiteMinder: {
    local: "desjardins-fed",
    dev: "desjardins-fed",
    certification: "desjardins-fed",
    integre: "desjardins-fed",
    preprod: "desjardins-fed",
    prod: "desjardins-fed",
  },
  // Votre redirectUri
  redirectUri: {
    local: document.location.href,
    dev: document.location.href,
    certification: document.location.href,
    integre: document.location.href,
    preprod: document.location.href,
    prod: document.location.href,
  },
  // Votre acceswebUrl
  acceswebUrl: {
    local: "https://accweb-martine.mouvdev.desjardins.com",
    dev: "https://accweb-martine.mouvdev.desjardins.com",
    certification: "https://accweb-01-20494.mouvdev.desjardins.com",
    integre: "https://accweb-01-20494.mouvdev.desjardins.com",
    preprod: "https://accweb-martine.mouvdev.desjardins.com",
    prod: "https://accweb.mouv.desjardins.com",
  },
};

export const getAccesWebUrl = (env: Environnement): string => {
  return Content.acceswebUrl[env];
};

export const getConfig = (env: Environnement, estParcourAssiste: boolean): MetaTagsArray => [
  {
    name: MetagTagNames.domain,
    content: Content.domain[env],
  },
  {
    name: MetagTagNames.clientId,
    content: Content.clientId[env],
  },
  {
    name: MetagTagNames.issuer,
    content: Content.issuer[env],
  },
  {
    name: MetagTagNames.redirectUri,
    content: Content.redirectUri[env],
  },
  {
    name: MetagTagNames.acceswebUrl,
    content: Content.acceswebUrl[env],
  },
  {
    name: MetagTagNames.audience,
    content: Content.audience[env],
  },
  {
    name: MetagTagNames.scope,
    content: Content.scope,
  },
  {
    name: MetagTagNames.creerSessionLegacy,
    content: Content.creerSessionLegacy,
  },
  {
    name: MetagTagNames.connection,
    content: estParcourAssiste ? Content.connectionSiteMinder[env] : Content.connection[env],
  },
  {
    name: MetagTagNames.moniteurActiviteActif,
    content: Content.moniteurActiviteActif,
  },
];
