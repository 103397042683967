import { CybermetrieService } from "@oel/js-cybermetrie";
import { CybermetrieParamsJAMPP } from "../models/CybermetrieParamsJAMPP";

export class CybermetriesJAMPP {
  static EnvoiePageObtenirReponse(cybermetrieParams: CybermetrieParamsJAMPP) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("typeProduit", "Personal Loan");
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("typeTransaction", cybermetrieParams.parcours);
    dimensionsPersonnalisees.set("extraAttribut", "emprunteurUnique");
    CybermetrieService.envoyerEvenement(
      {
        event: "interractionsPages",
        action: "etape-3b",
        category: "Formulaire",
        label: "frm - obtenir du financement - pret personnel",
      },
      dimensionsPersonnalisees
    );
  }

  static EnvoiePlanifierAppel(cybermetrieParams: CybermetrieParamsJAMPP) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("typeProduit", "Personal Loan");
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("typeTransaction", cybermetrieParams.parcours);
    dimensionsPersonnalisees.set("extraAttribut", "emprunteurUnique");
    dimensionsPersonnalisees.set("extraAttribut2", "etape-3b");

    CybermetrieService.envoyerEvenement(
      {
        event: "interractionsPages",
        action: "Clic",
        category: "Obtenir du financement",
        label: "obtenir du finanement - pret personnel - planifier un appel",
      },
      dimensionsPersonnalisees
    );
  }

  static EnvoieAffichageModaleEnregistrerQuitter(cybermetrieParams: CybermetrieParamsJAMPP) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("typeProduit", "Personal Loan");
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("typeTransaction", cybermetrieParams.parcours);
    dimensionsPersonnalisees.set("extraAttribut", "emprunteurUnique");
    dimensionsPersonnalisees.set("extraAttribut2", "etape-3b");

    CybermetrieService.envoyerEvenement(
      {
        event: "interractionsPages",
        action: "Affichage",
        category: "Obtenir du financement",
        label: "obtenir du financement - pret personnel - enregistrer et quitter",
      },
      dimensionsPersonnalisees
    );
  }

  static EnvoieClicBoutonEnregistrerQuitter(cybermetrieParams: CybermetrieParamsJAMPP) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("typeProduit", "Personal Loan");
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("typeTransaction", cybermetrieParams.parcours);
    dimensionsPersonnalisees.set("extraAttribut", "emprunteurUnique");
    dimensionsPersonnalisees.set("extraAttribut2", "etape-3b");

    CybermetrieService.envoyerEvenement(
      {
        event: "interractionsPages",
        action: "Clic",
        category: "Obtenir du financement",
        label: "obtenir du financement - pret personnel - enregistrer et quitter",
      },
      dimensionsPersonnalisees
    );
  }

  static EnvoieReponseDemande(cybermetrieParams: CybermetrieParamsJAMPP, decision?: string) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("typeProduit", "Personal Loan");
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("typeTransaction", cybermetrieParams.parcours);
    dimensionsPersonnalisees.set("extraAttribut", "emprunteurUnique");

    let resultat = decision === "ACC" ? "accepte" : "renseignements supplémentaires";

    CybermetrieService.envoyerEvenement(
      {
        event: "interractionsPages",
        action: "etape-3b | resultat assurance | saisis",
        category: "Interractions forms",
        label: `obtenir du financement - pret personnel - ${resultat}`,
      },
      dimensionsPersonnalisees
    );
  }

  static EnvoieErreurReponse(cybermetrieParams: CybermetrieParamsJAMPP) {
    const dimensionsPersonnalisees = new Map<string, string>();
    dimensionsPersonnalisees.set("typeProduit", "Personal Loan");
    dimensionsPersonnalisees.set("transactionId", cybermetrieParams.noDemande);
    dimensionsPersonnalisees.set("typeTransaction", cybermetrieParams.parcours);
    dimensionsPersonnalisees.set("extraAttribut", "emprunteurUnique");
    dimensionsPersonnalisees.set("messageErreur", "Choisir une option");
    CybermetrieService.envoyerEvenement(
      {
        event: "interractionsPages",
        action: "erreur utilisateur",
        category: "Formulaire",
        label: "frm - obtenir du financement - pret personnel",
      },
      dimensionsPersonnalisees
    );
  }
}
