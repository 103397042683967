import HeaderPageErreur from "../components/APRHN/HeaderPageErreur";
import ErreurFonctionnelle from "../components/APRHN/ErreurFonctionnelle";
import Footer from "../components/APRHN/Footer";
import "./pageErreurFonctionnelAPRHN.css";

function PageErreurFonctionnelAPRHN() {
  return (
    <div data-testid="page-erreur-fonctionnel-aprhn" className="Erreur page-erreur-fonctionnel-aprhn">
      <HeaderPageErreur />
      <ErreurFonctionnelle />
      <Footer positionPageFooter="erreurs" />
    </div>
  );
}

export default PageErreurFonctionnelAPRHN;
