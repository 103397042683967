import { InformationsPreAssurabilite } from "../../models";
import i18n from "../../resources/I18n";
import { INavigationParcours, obtenirNavigationUrl } from "../../util/Navigation";
import ModaleEnregistrer from "../JAMPP/ModaleEnregistrer";
import { DsdButton } from "../DSD/DsdButton";
import { DsdColumn } from "../DSD/DsdColumn";
import { DsdRow } from "../DSD/DsdRow";
import { useCybermetrieJAMPPContext } from "../utils/CybermetrieJAMPPContext";
import { CybermetriesJAMPP } from "../../util/CybermetrieJAMPP";
import { getMetaTagContent } from "../../scripts/getMetaTag";

interface IBoutonsProps {
  onClickContinuer?: () => void;
  onClickEtatModaleEnregistrer: (etat: boolean, declencheur: string) => void;
  modaleEnregistrerOuvert: boolean;
  informationsPreAssurabilite: InformationsPreAssurabilite;
  cookieParcoursAssurance: INavigationParcours;
  showBtnContinuer?: boolean;
  parcours: string;
}

function Boutons(props: Readonly<IBoutonsProps>) {
  let cybermetrieParams = useCybermetrieJAMPPContext();

  const onClickBoutonPrecedent = () => {
    const isApp = getMetaTagContent("oel-is-app") !== null ? getMetaTagContent("oel-is-app") : false;

    window.location.assign(
      `${obtenirNavigationUrl(props.cookieParcoursAssurance.idCallback)}redirection/${props.parcours}/${
        props.cookieParcoursAssurance.langue
      }/back?idSouscription=${props.cookieParcoursAssurance.idSouscription}&idAssure=${
        props.cookieParcoursAssurance.idAssure
      }&isApp=${isApp}`
    );
  };

  const afficherModaleEnregistrer = () => {
    props.onClickEtatModaleEnregistrer(true, "btn-enregistrer");
    CybermetriesJAMPP.EnvoieAffichageModaleEnregistrerQuitter(cybermetrieParams);
  };

  return (
    <>
      <ModaleEnregistrer
        modaleOuvert={props.modaleEnregistrerOuvert}
        onClickModaleEnregistrerFermer={props.onClickEtatModaleEnregistrer}
        dateExpirationOffre={props.informationsPreAssurabilite.dateExpirationOffre}
        cookieParcoursAssurance={props.cookieParcoursAssurance}
        parcours={props.parcours}
      />
      <DsdRow id="boutons-container">
        <DsdColumn>
          {props.showBtnContinuer !== false && (
            <DsdButton datatestid="btn-continuer" variant="primary" id="btn-continuer" onClick={props.onClickContinuer}>
              {i18n.t("assurabilite:btn-continuer")}
            </DsdButton>
          )}
          <DsdButton id="btn-precedent" datatestid="btn-precedent" onClick={onClickBoutonPrecedent}>
            {i18n.t("assurabilite:btn-precedent")}
          </DsdButton>
          <DsdButton id="btn-enregistrer" datatestid="btn-enregistrer" onClick={afficherModaleEnregistrer}>
            {i18n.t("assurabilite:btn-enregistrer")}
          </DsdButton>
        </DsdColumn>
      </DsdRow>
    </>
  );
}

export default Boutons;
