import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import { useAuth0 } from "./components/Auth0/useAuth0";
import Configuration from "./Configuration";
import PageErreurFonctionnelAPRHN from "./pages/PageErreurFonctionnelAPRHN";
import PageErreurTechniqueAPRHN from "./pages/PageErreurTechniqueAPRHN";
import PageAPRHN from "./pages/PageAPRHN";
import i18n from "./resources/I18n";
import { Environnement } from "./util/Constantes";
import { getCookie, obtenirLangueCookie } from "./util/Cookies";
import { setCookieParamsMock } from "./util/MockCookie";
import { INavigationParcours, obtenirParamsNavigationURL } from "./util/Navigation";
import { CybermetrieService } from "@oel/js-cybermetrie";
import PageJAMPP from "./pages/PageJAMPP";
import * as TYPE_PARCOURS from "./constants/typeParcours";
import { setMetaTag } from "./scripts/setMetaTag";

let isCybermetrieInitialized = false;

function App() {
  let navigationParcoursAssurance: INavigationParcours;

  //Obtenir la route
  const location = useLocation();
  const chemin = location.pathname;

  //Obtenir et gérer JAMPP avec les params de l'URL
  if (chemin.includes(TYPE_PARCOURS.ASSISTE) || chemin.includes(TYPE_PARCOURS.AUTONOME)) {
    navigationParcoursAssurance = obtenirParamsNavigationURL();

    //Gestion de la langue
    document.documentElement.lang = navigationParcoursAssurance.langue;
    i18n.changeLanguage(navigationParcoursAssurance.langue);
  } else if (chemin === "/") {
    //Ajout du cookie parcours_assurance pour les mocks de APRHN
    if (Configuration.env === Environnement.DEV || Configuration.env === Environnement.LOCAL) {
      setCookieParamsMock();
    }

    //Gestion de la langue
    const langue = obtenirLangueCookie();
    document.documentElement.lang = langue;
    i18n.changeLanguage(langue);

    navigationParcoursAssurance = getCookie("parcours_assurance");
  }

  const auth0 = useAuth0();
  //Initialisation de l'authentification
  if (Configuration.obtenirConfigurations().authenfication) {
    auth0.initialiser(Configuration.env, chemin.includes(TYPE_PARCOURS.ASSISTE));
  }

  if (!isCybermetrieInitialized) {
    setMetaTag();
    CybermetrieService.initialiser();
    isCybermetrieInitialized = true;
  }

  return (
    <Routes>
      <Route path={i18n.t("assurabilite:url-erreur-technique")} element={<PageErreurTechniqueAPRHN />} />
      <Route path={i18n.t("assurabilite:url-erreur-fonctionnelle")} element={<PageErreurFonctionnelAPRHN />} />
      <Route path="/" element={<PageAPRHN cookieParcoursAssurance={navigationParcoursAssurance} />} />
      <Route
        path="/assiste"
        element={<PageJAMPP cookieParcoursAssurance={navigationParcoursAssurance} parcours={TYPE_PARCOURS.ASSISTE} />}
      />
      <Route
        path="/autonome"
        element={<PageJAMPP cookieParcoursAssurance={navigationParcoursAssurance} parcours={TYPE_PARCOURS.AUTONOME} />}
      />
    </Routes>
  );
}

export default App;
