import React, { ReactNode } from "react";

interface IDsdProgessBarProps {
  id?: string;
  percentage: string;
  currentStep: string;
  steps: string;
  children: ReactNode;
  datatestid: string;
}

export const DsdProgressBar: React.FC<IDsdProgessBarProps> = ({
  datatestid,
  id,
  currentStep,
  steps,
  percentage,
  children,
}) => {
  return (
    <dsd-progress data-testid={datatestid} id={id} percentage={percentage} current-step={currentStep} steps={steps}>
      {children}
    </dsd-progress>
  );
};
