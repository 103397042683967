export function setMetaTag() {
  const isApp = typeof window.ServicesMobilesDesjardins !== "undefined";

  const metaTag = document.createElement("meta");
  metaTag.name = "oel-is-app";
  metaTag.content = "false";
  if (isApp) {
    metaTag.content = "true";
  }
  document.head.appendChild(metaTag);
}
